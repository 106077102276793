const loadGtm = () => {
    let tag = "";

    const defaultLanguageEN = window.location.href.includes("teammatehub");

    const defaultLanguageFR = window.location.href.includes("espaceemployes");

    if (defaultLanguageEN) {
        tag = "G-4S0Q52WLND"; //en
    }

    if (defaultLanguageFR) {
        tag = "'G-1NV3S0F5XW"; //fr
    }

    const existingScript = document.getElementById("scriptGtm");

    if (!existingScript) {
        const scriptLoad = document.createElement("script");
        scriptLoad.id = "scriptGtm";
        scriptLoad.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
        scriptLoad.async = true;
        document.head.appendChild(scriptLoad);

        const script = document.createElement("script");

        const code = `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());  
                    gtag('config', '${tag}');
                  `;

        script.text = code;

        document.head.appendChild(script);
    }
};

export default loadGtm;
